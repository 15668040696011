// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal {
    z-index: 2021;
  }
  .extended-instructions .modal-dialog {
    max-width: 100vw;
    height: 100vh;
    margin: auto;
  }
  .syllabus-details .modal-dialog {
    max-width: 700px;
    margin: auto;
    margin-top: 50vh;
    transform: translateY(-50%) !important;
  }
  .syllabus-details .modal-dialog .modal-content {
    padding: 10px;
  }
  .new-day .modal-dialog {
    max-width: 700px;
    margin: auto;
    margin-top: 50vh;
    transform: translateY(-50%) !important;
  }
  .new-day .modal-dialog .modal-content {
    padding: 10px;
  }
  .new-day .col-12 p {
    font-size: 12px;
    color: black;
  }
  .new-day .col-6 p {
    font-size: 12px;
    color: black;
  }
  .new-day .card-header {
    padding: 5px;
  }
  .new-day .card-header h2 {
    font-size: 12px;
  }
  .new-day .card-header button {
    font-size: 12px;
    padding: 0;
  }
  .new-day .modal-title {
    color: black;
  }
  .new-day .modal-body p {
    color: black;
  }
  .search-replace-results {
    background-color: #545454;
    color: #dfdfdf;
    max-height: 250px;
    overflow-y: auto;
    text-align: left;
    font-size: 12px;
    padding: 5px;
  }
  .search-replace-results .search-replace-results .asset_type {
    color: #b8e2ff;
  }
  `, "",{"version":3,"sources":["webpack://./src/styles/_modals.css"],"names":[],"mappings":"AAAA;IACI,aAAa;EACf;EACA;IACE,gBAAgB;IAChB,aAAa;IACb,YAAY;EACd;EACA;IACE,gBAAgB;IAChB,YAAY;IACZ,gBAAgB;IAChB,sCAAsC;EACxC;EACA;IACE,aAAa;EACf;EACA;IACE,gBAAgB;IAChB,YAAY;IACZ,gBAAgB;IAChB,sCAAsC;EACxC;EACA;IACE,aAAa;EACf;EACA;IACE,eAAe;IACf,YAAY;EACd;EACA;IACE,eAAe;IACf,YAAY;EACd;EACA;IACE,YAAY;EACd;EACA;IACE,eAAe;EACjB;EACA;IACE,eAAe;IACf,UAAU;EACZ;EACA;IACE,YAAY;EACd;EACA;IACE,YAAY;EACd;EACA;IACE,yBAAyB;IACzB,cAAc;IACd,iBAAiB;IACjB,gBAAgB;IAChB,gBAAgB;IAChB,eAAe;IACf,YAAY;EACd;EACA;IACE,cAAc;EAChB","sourcesContent":[".modal {\n    z-index: 2021;\n  }\n  .extended-instructions .modal-dialog {\n    max-width: 100vw;\n    height: 100vh;\n    margin: auto;\n  }\n  .syllabus-details .modal-dialog {\n    max-width: 700px;\n    margin: auto;\n    margin-top: 50vh;\n    transform: translateY(-50%) !important;\n  }\n  .syllabus-details .modal-dialog .modal-content {\n    padding: 10px;\n  }\n  .new-day .modal-dialog {\n    max-width: 700px;\n    margin: auto;\n    margin-top: 50vh;\n    transform: translateY(-50%) !important;\n  }\n  .new-day .modal-dialog .modal-content {\n    padding: 10px;\n  }\n  .new-day .col-12 p {\n    font-size: 12px;\n    color: black;\n  }\n  .new-day .col-6 p {\n    font-size: 12px;\n    color: black;\n  }\n  .new-day .card-header {\n    padding: 5px;\n  }\n  .new-day .card-header h2 {\n    font-size: 12px;\n  }\n  .new-day .card-header button {\n    font-size: 12px;\n    padding: 0;\n  }\n  .new-day .modal-title {\n    color: black;\n  }\n  .new-day .modal-body p {\n    color: black;\n  }\n  .search-replace-results {\n    background-color: #545454;\n    color: #dfdfdf;\n    max-height: 250px;\n    overflow-y: auto;\n    text-align: left;\n    font-size: 12px;\n    padding: 5px;\n  }\n  .search-replace-results .search-replace-results .asset_type {\n    color: #b8e2ff;\n  }\n  "],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sidebar-btn {
    z-index: 100;
    top: 56px;
    left: 10px;
  }
  .sidebar {
    width: 300px;
    z-index: 100;
    background: #272727;
    color: white;
    height: 100vh;
    overflow-y: auto;
  }
  .sidebar::-webkit-scrollbar {
    width: 10px;
  }
  .sidebar::-webkit-scrollbar-track {
    background: #b3b1b1;
    border-radius: 10px;
  }
  .sidebar::-webkit-scrollbar-thumb {
    background: #dfc346;
    border-radius: 10px;
  }
  .sidebar::-webkit-scrollbar-thumb:hover {
    background: #646464;
    border-radius: 10px;
  }
  .sidebar::-webkit-scrollbar-thumb:active {
    background: #444;
    border-radius: 10px;
  }
  .sidebar .back-btn {
    width: 85px;
  }
  .sidebar .content {
    padding: 0px 10px;
  }
  .sidebar .modal-dialog {
    margin: 0;
    color: black;
  }
  .sidebar .modal-dialog .modal-content {
    border-radius: 0;
  }
  .sidebar li.content-piece {
    background: #464646;
  }
  .sidebar li.content-piece:hover {
    background: #5a5a5a;
  }
  `, "",{"version":3,"sources":["webpack://./src/styles/_sidebar.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,SAAS;IACT,UAAU;EACZ;EACA;IACE,YAAY;IACZ,YAAY;IACZ,mBAAmB;IACnB,YAAY;IACZ,aAAa;IACb,gBAAgB;EAClB;EACA;IACE,WAAW;EACb;EACA;IACE,mBAAmB;IACnB,mBAAmB;EACrB;EACA;IACE,mBAAmB;IACnB,mBAAmB;EACrB;EACA;IACE,mBAAmB;IACnB,mBAAmB;EACrB;EACA;IACE,gBAAgB;IAChB,mBAAmB;EACrB;EACA;IACE,WAAW;EACb;EACA;IACE,iBAAiB;EACnB;EACA;IACE,SAAS;IACT,YAAY;EACd;EACA;IACE,gBAAgB;EAClB;EACA;IACE,mBAAmB;EACrB;EACA;IACE,mBAAmB;EACrB","sourcesContent":[".sidebar-btn {\n    z-index: 100;\n    top: 56px;\n    left: 10px;\n  }\n  .sidebar {\n    width: 300px;\n    z-index: 100;\n    background: #272727;\n    color: white;\n    height: 100vh;\n    overflow-y: auto;\n  }\n  .sidebar::-webkit-scrollbar {\n    width: 10px;\n  }\n  .sidebar::-webkit-scrollbar-track {\n    background: #b3b1b1;\n    border-radius: 10px;\n  }\n  .sidebar::-webkit-scrollbar-thumb {\n    background: #dfc346;\n    border-radius: 10px;\n  }\n  .sidebar::-webkit-scrollbar-thumb:hover {\n    background: #646464;\n    border-radius: 10px;\n  }\n  .sidebar::-webkit-scrollbar-thumb:active {\n    background: #444;\n    border-radius: 10px;\n  }\n  .sidebar .back-btn {\n    width: 85px;\n  }\n  .sidebar .content {\n    padding: 0px 10px;\n  }\n  .sidebar .modal-dialog {\n    margin: 0;\n    color: black;\n  }\n  .sidebar .modal-dialog .modal-content {\n    border-radius: 0;\n  }\n  .sidebar li.content-piece {\n    background: #464646;\n  }\n  .sidebar li.content-piece:hover {\n    background: #5a5a5a;\n  }\n  "],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
